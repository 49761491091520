// palette
$primary-light: #fff;
$primary-main: #32414b;
$primary-dark: #32414b;
$primary-text: #fff;

$secondary-light: #a51e37;
$secondary-main: #a51e37;
$secondary-dark: #7a1629;
$secondary-text: #565c65;

$gold-main: #997000;

$gray-background: #f2f2f2;
$gray-border: #dddddd;

// variables
$max-width: 1200px;