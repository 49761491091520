@import "../../../node_modules/@fontsource/open-sans";

* {
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
}

body {
    width: 100%;
    font-family: "Open Sans", sans-serif;
    overflow-x: hidden;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.page-wrapper {
    flex-grow: 1;
}

.banner-container {
    @include flex(column, center, center, hidden);
}

.banner-image {
    height: 492px;

    &--stretch {
        @extend .banner-image;
        width: 100%;
        height: auto;
    }
}

.banner-background {
    @include flex();
    width: 100%;
    max-width: 100%;
    height: 12px;
    background: $secondary-light;
}

.overview-banner {
    position: absolute;
    right: calc(52vw - $max-width / 2);
    bottom: 56px;

    @media (max-width: 1080px) {
        right: 0;
    }

    a {
        display: inline-block;
        text-decoration: none;
        padding: 36px 24px;

        &:hover:before {
            opacity: 1;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $primary-main;
            opacity: .8;
            transition: .5s ease-in-out;
        }
    }
}

.description-container {
    @include flex();
    width: 100%;
    max-width: $max-width;
    margin-bottom: 50px;
}

.logo-container {
    @include flex(row, center, flex-start);
    height: 150px;
    max-width: $max-width;
}

.logo {
    margin-left: 48px;
    height: 100px;
}

.nav-container {
    @include flex();
}

.nav {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    max-width: calc($max-width + 56px);
    background: $primary-main;
    margin-bottom: 16px;
    border-top: 3px solid $primary-main;
    z-index: 2;

    a, button {
        display: inline-flex;
        align-items: center;
        padding: 0 32px;
        line-height: 56px;
        color: $primary-text;
        background: transparent;
        border: none;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        transition: .1s ease-in-out;
        text-transform: uppercase;
        cursor: pointer;

        &.active {
            color: $primary-main;
            background: $gray-background;
        }

        &:hover {
            color: $primary-text;
            background: $secondary-light;
        }

        &:disabled {
            pointer-events: none;
            opacity: .5;
        }

        svg {
            font-size: 22px;
            margin-left: 4px;
        }
    }

    .language-switch-container {
        margin-left: auto;

        a {
            cursor: pointer;
        }
    }
}

footer {
    min-height: 100px;
}

.footer {
    width: 100%;
    background: $gray-background;
    padding: 32px;
    margin-top: 64px;
    border-top: 1px solid #fff;
}

.versions-container {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    opacity: .5;
}

.footer-info-container {
    @include flex(row, flex-start, space-between);
    max-width: $max-width;
}

.studies-table {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid $gray-border;
    border-top: 3px solid $secondary-light;
    padding: 24px;

    h2 {
        width: 100%;
        margin-bottom: 24px;
    }

    &__column {
        @include flex(column, flex-start);
        flex: 1;
    }
}

.logo-user-wraper {
    @include flex(row, center, flex-start);
    border-bottom: 6px solid $secondary-light;
    height: 80px;
    z-index: 3;
}

.logo-user-container {
    @include flex(row, center, flex-start);
    flex-wrap: nowrap;
    width: 100%;
    padding: 0 16px;

    button {
        cursor: pointer;
    }

    a.active {
        color: $secondary-main;
    }

    a, p, button {
        display: inline-flex;
        align-items: center;
        padding: 7px 12px;
        margin: 0 4px;
        line-height: 60px;
        color: $secondary-text;
        background: white;
        border: none;
        font-size: 16px;
        text-decoration: none;
        transition: .15s ease-in-out;
        cursor: pointer;

        &:disabled {
            pointer-events: none;
            opacity: .5;
        }

        &:hover {
            filter: brightness(0.95);
        }

        &:hover svg {
            color: $secondary-main;
        }

        svg {
            font-size: 22px;
            margin-left: 4px;
        }

        &:hover b {
            transform: scale(1);
        }


        b {
            padding: 12px;
            border-radius: 6px;
            box-shadow: #999 0 -1px 7px;
            background: #fff;
            color: #222;
            font-size: 13px !important;
            line-height: 19px !important;
            text-transform: none !important;
            text-decoration: none;

            position: absolute;
            top: calc(100% + 20px);
            right: 0;
            transform: scale(0);
            white-space: nowrap;

            &:before {
                content: '';
                position: absolute;
                top: -5px;
                right: 44px;
                transform: rotate(45deg);
                width: 10px;
                height: 10px;
                background: #fff;
                box-shadow: -4px -4px 4px 0 #99999950;
            }
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: -20px;
                width: 100%;
                height: 20px;
            }
        }
    }
}

.logo-user {
    width: auto;
    height: 42px;
    max-height: 42px;
    margin-right: 16px;
}

.main-user, .main-admission {
    @include flex(column);
    max-width: calc($max-width - 200px);
    padding: 16px;
}

.accordion {
    width: 100%;
    max-height: calc(44px);
    border: 1px solid $gray-border;
    margin-bottom: 4px;
    padding: 12px 8px 24px;
    overflow: hidden;

    &.active {
        max-height: max-content;
        overflow: visible;
        z-index: 2;
    }

    &.disabled {
        pointer-events: none;
    }

    .title {
        font-size: 19px;
        line-height: 42px;
        color: $secondary-text;
    }

    .buttons {
        button {
            margin-left: 16px;
        }
        a {
            display: inline-block;
            margin: 24px 50% 0;
            transform: translateX(-50%);
        }
    }

    p, a {
        font-size: 14px;
        margin-left: 16px;

        svg {
            font-size: 19px;
            margin: 0 4px -4px 0;
        }
    }

    a {
        color: $secondary-main;
        margin-left: 316px;
    }

    .content {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .input-row {
            @include flex(row, center, flex-start);
            width: 100%;
            margin-bottom: 4px;
        }

        p {
            white-space: nowrap;
            width: 300px;
            text-align: right;
            padding-right: 16px;
        }

        input {
            min-width: 300px;
            border: 1px solid $primary-main;
            padding: 8px;
        }
    }
}

.error-page {
    @include flex();
}

.footer-user {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 32px;
    margin-top: 64px;
    border-top: 1px solid $gray-border;

    & > *:not(:last-child) {
        margin-right: 16px;
    }

    & > *:first-child {
        margin-right: auto;
    }
}

.documents-container, .messages-inner-container {
    max-width: 1500px;
    margin: 25px auto 0 auto;
    padding: 0 20px;

    .MuiCircularProgress-root, &__empty {
        display: block;
        margin: 0 auto;
        width: fit-content;
    }
}

.table {
    table-layout: fixed;
    
    th, td {
        font-size: 16px;
        overflow-wrap: break-word;

        button {
            min-width: 0;
            width: 100%;
        }
    }

    th {
        font-weight: bold;
        vertical-align: top;
    }

    td {
        span[role="progressbar"] {
            margin: 0;
        }
    }

    a {
        color: $primary-main;
    }
}

.refresh-button {
    font-size: 18px;
}

[aria-label="pagination navigation"] {
    margin-top: 15px;
}

.breadcrumbs {
    padding: 0 24px;

    a {
        font-size: 14px;
        color: $secondary-dark !important;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.download-document {
    font-size: 24px;
}

.help-button svg {
    font-size: 24px;
}

div.timer {
    font-size: 20px;
    font-weight: bold;
    margin-top:-15px;
    margin-bottom: 5px;
}

form {
    .MuiIconButton-root {
        font-size: 20px;
    }
}

.messages-history {
    .MuiIconButton-root {
        font-size: 24px;
    }
}