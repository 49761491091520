@import 'theme';
// First mock's theme, then common styles
@import 'common-frontend/assets/scss';
@import 'components';

input[type="file"] {
  display: none;
}

.mt-8 {
  margin-top: 8px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.mt-48 {
  margin-top: 48px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-48 {
  margin-bottom: 48px !important;
}

.ml-auto {
  margin-left: auto;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.7;
}